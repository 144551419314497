import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
	inject,
	Injectable,
	OnDestroy,
	PLATFORM_ID,
	RendererFactory2,
} from '@angular/core';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { Meta } from '@angular/platform-browser';

@Injectable({
	providedIn: 'root',
})
export class ThemeService implements OnDestroy {
	private _platformId = inject(PLATFORM_ID);
	private _renderer = inject(RendererFactory2).createRenderer(null, null);
	private _document = inject(DOCUMENT);
	private _meta = inject(Meta);

	private _theme$ = new ReplaySubject<'light' | 'dark'>(1);
	public theme$ = this._theme$.asObservable();
	private _destroyed$ = new Subject<void>();

	constructor() {
		if (isPlatformBrowser(this._platformId)) {
			this._theme$.next(
				localStorage.getItem('theme') === 'dark' ? 'dark' : 'light'
			);
		}

		this.toggleClassOnThemeChanges();
	}

	private toggleClassOnThemeChanges(): void {
		this.theme$.pipe(takeUntil(this._destroyed$)).subscribe((theme) => {
			if (theme === 'dark') {
				this._renderer.addClass(this._document.documentElement, 'dark');
				this._meta.updateTag({ name: 'theme-color', content: '#09090b' });
			} else {
				this._meta.updateTag({ name: 'theme-color', content: '#fff' });
				if (this._document.documentElement.className.includes('dark')) {
					this._renderer.removeClass(this._document.documentElement, 'dark');
				}
			}
		});
	}

	public toggleDarkMode(): void {
		const newTheme =
			localStorage.getItem('theme') === 'dark' ? 'light' : 'dark';
		localStorage.setItem('theme', newTheme);

		this._theme$.next(newTheme);
	}

	public setDark() {
		this._theme$.next('dark');
		if (isPlatformBrowser(this._platformId)) {
			localStorage.setItem('theme', 'dark');
		}
	}

	public setLight() {
		this._theme$.next('light');

		if (isPlatformBrowser(this._platformId)) {
			localStorage.setItem('theme', 'light');
		}
	}

	// E. Clean up our subscriptions when the service gets destroyed
	public ngOnDestroy(): void {
		this._destroyed$.next();
		this._destroyed$.complete();
	}
}
